import {useEffect} from "react";
import useScroll from "../../hooks/view/useScroll";
import {EVT_ADDED_MESSAGE} from "../../hooks/domain/useMessage";
import {adminApi} from "../../aaxios";


async function downloadFile(serviceFileId) {
    try {
        // 파일 요청
        const response = await adminApi(`/admin/pdf/${serviceFileId}`, {
            responseType: 'blob'
        });
        const blob = response.data;
        const url = window.URL.createObjectURL(blob);

        // 새 창에서 PDF 열기
        window.open(url, '_blank');
        // 다운로드 쪽..
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = `${serviceFileId}.pdf`;
        // document.body.appendChild(a); // append the element to the dom
        // a.click();
        // a.remove(); // afterwards, remove the element

    } catch (error) {
        console.error("Error downloading the file:", error);
    }
}

function getCurrentTime() {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedHours = hours > 12 ? hours - 12 : hours;
    const period = hours >= 12 ? '오후' : '오전';
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${period} ${formattedHours}:${formattedMinutes}`;
}


function TextMode({messages, messageEvent, isLoading}) {
    const {
        messagesEndRef,
        scrollToBottom
    } = useScroll()

    useEffect(() => {
        if (messageEvent === EVT_ADDED_MESSAGE) {
            scrollToBottom()
        }
    }, [messageEvent]);

    return (
        <>
            {messages.map((message, index) => (
                <div
                    className={`msg-bubble ${message.sender === 'user' ? 'msg-right' : 'msg-left'}`}
                    key={index}>
                    <div className='user-say-text'>{message.text}</div>
                    <div className="msg-time">{getCurrentTime()}</div>
                </div>
            ))}
            <div ref={messagesEndRef}/>
            {isLoading && <div className="loading-indicator"></div>}
        </>
    )

}

function PdfView({data}) {
    if (data['vector_list'].length === 0) return null

    async function onClick(serviceFileId) {
        await downloadFile(serviceFileId)
    }

    return (
        <>
            {
                data['vector_list'].map(vector => {
                    return (
                        <div
                            className={`msg-bubble msg-right`}
                            style={{cursor: 'pointer'}}
                            onClick={async () => {
                                await onClick(vector['service_file']['id'])
                            }}
                            key={vector['id']}>
                            <div className='user-say-text'>{vector['service_file']['oring_filename']}</div>
                        </div>
                    )
                })
            }
        </>
    )
}


function PdfModeUser({message}) {
    if (message.sender !== 'user') {
        return null
    }
    return (
        <div
            className={`msg-bubble msg-right`}>
            <div className='user-say-text'>{message.text}</div>
            <div className="msg-time">{getCurrentTime()}</div>
        </div>
    )
}

function PdfModeBot({message, messagesEndRef, isLoading}) {
    if (message.sender === 'user') {
        return null
    }

    async function onClick(serviceFileId) {
        await downloadFile(serviceFileId)
    }


    return (
        <>
            {message['resData']['vector_list'].map((v) => {
                return (
                    <div
                        onClick={() => onClick(v['service_file']['id'])}
                        className={`msg-bubble msg-left`}
                        style={{cursor: "pointer"}}
                    >
                        <div className='user-say-text'>
                            <p>
                                <strong>파일&nbsp;이름:&nbsp;{v['service_file']['oring_filename']}
                                    &nbsp;<img src="img/pdf.png" alt="pdf파일"/>
                                </strong>
                            </p>
                            <p>
                                <strong>페이지:&nbsp;{v['page']}</strong>
                            </p>
                            {/*<p>*/}
                            {/*    <strong>줄 번호:&nbsp;{v['page_line']}</strong>*/}
                            {/*</p>*/}
                        </div>
                        <br/>
                        <div className='user-say-text'>
                            <p>
                                <strong>내용:&nbsp;</strong>
                                {v['content']}
                            </p>

                        </div>
                    </div>
                )
            })}
        </>
    )
}


function PdfMode({messages, messageEvent, isLoading}) {
    const {
        messagesEndRef,
        scrollToBottom
    } = useScroll()

    useEffect(() => {
        if (messageEvent === EVT_ADDED_MESSAGE) {
            scrollToBottom()
        }
    }, [messageEvent]);

    return (
        <>
            {
                messages.map((m, idx) => {
                    return (
                        <>
                            <PdfModeUser key={idx + "_"} message={m}/>
                            <PdfModeBot key={idx + "__"} message={m}/>
                        </>
                    )
                })
            }


            <div ref={messagesEndRef}/>
            {isLoading && <div className="loading-indicator"></div>}
        </>
    )
}

export default function MessageBody(props) {
    const {viewMode, messages, isLoading, messageEvent} = props

    const targetMessages = messages.filter(m => m['query_type'] === viewMode)

    if (viewMode === 'text') {
        return (
            <TextMode isLoading={isLoading} messages={targetMessages} messageEvent={messageEvent}/>
        )
    }

    return (
        <PdfMode isLoading={isLoading} messages={targetMessages} messageEvent={messageEvent}/>
    )
}