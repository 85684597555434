import {useEffect, useState} from "react";
import {adminApi} from "../../aaxios";


async function makeSessionId() {
    // const session_id = localStorage.getItem("temp_session_id");
    // if (session_id) return session_id

    try {
        const response = await adminApi.post('/session/temp');
        const result = response.data
        const new_id = result['data']['id']
        localStorage.setItem("temp_session_id", new_id);
        return new_id
    } catch (error) {
        return null
    }
}


export default function useSession() {
    const [sessionId, setSessionId] = useState(null);
    useEffect(() => {
        makeSessionId().then(data => {
            // console.log("session_id >", data)
            setSessionId(data)
        })
    }, []);

    return {
        sessionId
    }
}