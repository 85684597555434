import React from "react";

function getBtnBgColor(chatMode) {
    let aiBgColor = "#97add4"
    let pdfBgColor = "#97add4"

    if (chatMode === 'text') {
        aiBgColor = '#2c549b'
    }

    if (chatMode === 'pdf') {
        pdfBgColor = '#2c549b'
    }

    return {
        aiBgColor,
        pdfBgColor
    }
}

export default function ChatBotHeader(props) {
    const {onChangeChatMode, chatMode} = props

    function handleChatMode(nextChatMode) {
        onChangeChatMode && onChangeChatMode(nextChatMode)
    }

    const {aiBgColor, pdfBgColor} = getBtnBgColor(chatMode)

    return (
        <header className='msger-header'>
            <div className='msger-header-title'>
                <div className='header-logo-frame'>
                    <img style={{width: "48px"}} src='img/img_logo_active.png' alt="Logo"></img>
                </div>
            </div>

            <div>
                <button
                    className="rounded-chip-admin"
                    style={{cursor: 'pointer', backgroundColor: pdfBgColor}}
                    onClick={() => handleChatMode("pdf")}
                >PDF 검색
                </button>
                <button
                    className="rounded-chip-admin"
                    style={{cursor: 'pointer', backgroundColor: aiBgColor}}
                    onClick={() => handleChatMode("text")}
                >AI 채팅
                </button>
            </div>
            {/*<button className="rounded-chip-admin"*/
            }
            {/*        style={{cursor:'pointer'}}*/
            }
            {/*        onClick={handleConsultation}>문의하기</button>*/
            }
        </header>
    )
}