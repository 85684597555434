import {adminApi} from "../../aaxios";
import {useEffect, useState} from "react";

async function fetchFAQList() {
    try {
        const response = await adminApi.get('/faq/list');
        if (response.status === 200) {
            return response.data['data']['page'].map(item => item['content'])
        }
    } catch (err) {
        return []
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // 요소를 서로 바꿈
    }
    return array;
}

function showMaxFive(arr, maxVal = 5) {
    return arr.slice(0, maxVal);
}

export default function useFaq() {
    const [faqs, setFaqs] = useState([])
    useEffect(() => {
        fetchFAQList().then(data => {
            setFaqs(data)
        })
    }, [])

    return {
        faqs: showMaxFive(shuffleArray(faqs)),
    }
}