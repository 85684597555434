import React, {useState} from "react";
import ConsultationModal from "../Components/ChatBot/ConsultationModal";
import ChatBotHeader from "../Components/ChatBot/ChatBotHeader";
import OnceViewPopUp from "../Components/ChatBot/OnceViewPopUp";
import Loading from "../Components/ChatBot/Loading";
import FAQChipList from "../Components/ChatBot/FAQChipList";
import InputBar from "../Components/ChatBot/InputBar";
import MessageBody from "../Components/ChatBot/MessageBody";
import useFaq from "../hooks/domain/useFaq";
import useMessage from "../hooks/domain/useMessage";
import useScreen from "../hooks/view/useScreen";
import useConsultation from "../hooks/domain/useConsultation";
import useSession from "../hooks/domain/useSession";

import '../App.css';

const GREETING_MESSAGE = '질문에 해당하는 정보를 바탕으로 AI챗봇 답변을 드리겠습니다'

export default function ChatBot(props) {
    const {sessionId} = useSession()
    const {
        messages,
        addMessage,
        messageEvent,
        isMessageLoading,
        viewMode,
        onChangeViewMode
    } = useMessage({
        sessionId
    })
    const {faqs} = useFaq()
    const {submitUserInfo} = useConsultation()
    useScreen()


    function handleClickFaq(faq) {
        addMessage({
            text: faq,
            sender: 'user'
        })
    }

    function handleSubmitInput({text}) {
        return addMessage({
            text,
            sender: 'user'
        })
    }


    return (
        <>
            <div className={`chat-container ${isMessageLoading ? 'blurred' : ''}`}>
                <div className='wrap'>
                    <div className='container'>
                        <Loading isLoading={isMessageLoading}/>
                        <div className='msger'>
                            <ChatBotHeader chatMode={viewMode} onChangeChatMode={onChangeViewMode}/>
                            <main className='msger-chat msger-chat-frame'>
                                <section className='msger-chat-prompt'>
                                    <div className='scroll-prompt'>
                                        <div className='greeting'>{GREETING_MESSAGE}</div>
                                        <MessageBody messages={messages}
                                                     viewMode={viewMode}
                                                     messageEvent={messageEvent}/>
                                    </div>
                                </section>
                                <div className='msger-form'>
                                    <FAQChipList faqs={faqs} onClick={handleClickFaq}/>
                                    <InputBar onSubmit={handleSubmitInput}/>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}